import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import {
  NomeTipoPortal,
  NomePortal,
  linkArquivos,
} from "../../../../../../../services/variables";
import { logoPortalBase64 } from "../../../../../../../services/logoBase64";

export default function PDFgenerate(files, infor) {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const data = files;
  const dataInfo = infor;

  const dateRender = (dateReq) => {
    const nameMonth = {
      0: "01",
      1: "02",
      2: "03",
      3: "04",
      4: "05",
      5: "06",
      6: "07",
      7: "08",
      8: "09",
      9: "10",
      10: "11",
      11: "12",
    };
    const date = new Date(dateReq);
    if (dateReq == "") {
      return "";
    } else {
      date.setDate(date.getDate() + 1);

      var day = date.getDate();
      if (date.getDate() < 10) {
        var day = "0" + date.getDate();
      }
      const formatDate =
        day + "/" + nameMonth[date.getMonth()] + "/" + date.getFullYear();

      return formatDate;
    }
  };

  const moneyFrom = (money) => {
    if (money == "" || typeof money == String) {
    } else {
      var format = { minimumFractionDigits: 2, currency: "BRL" };
      return money.toLocaleString("pt-BR", format);
    }
  };

  const arrayData2 = [
    [
      { text: "DATA MOVIMENTAÇÃO", bold: true, fontSize: 11 },
      { text: "NOME", bold: true, fontSize: 11 },
      { text: "ASSUNTO", bold: true, fontSize: 11 },
    ],
  ];
  const arrayInfor = [];

  arrayInfor.push([
    {
      //fontSize: 10
      columns: [
        {
          width: 150,
          text: "Número Processo:",
          style: "subheader",
          bold: true,
          fontSize: 10,
        },
        { text: dataInfo.NUMEROPROCESSO, fontSize: 10 },
      ],
    },
    {
      columns: [
        {
          width: 150,
          text: "Número Instrumento:",
          style: "subheader",
          bold: true,
          fontSize: 10,
        },
        { text: dataInfo.NUMEROINSTRUMENTO, fontSize: 10 },
      ],
    },
    {
      columns: [
        {
          width: 150,
          text: "Exercício:",
          style: "subheader",
          bold: true,
          fontSize: 10,
        },
        { text: dataInfo.EXERCICIO, fontSize: 10 },
      ],
    },
    {
      columns: [
        {
          width: 150,
          text: "Unidade:",
          style: "subheader",
          bold: true,
          fontSize: 10,
        },
        { text: dataInfo.UNIDADE_NOME, fontSize: 10 },
      ],
    },
    {
      columns: [
        {
          width: 150,
          text: "Modalidade:",
          style: "subheader",
          bold: true,
          fontSize: 10,
        },
        { text: dataInfo.MODALIDADE_NOME, fontSize: 10 },
      ],
    },
    {
      columns: [
        {
          width: 150,
          text: "Regime Execução Obra:",
          style: "subheader",
          bold: true,
          fontSize: 10,
        },
        { text: dataInfo.REGIME_OBRA_NOME, fontSize: 10 },
      ],
    },
    {
      columns: [
        {
          width: 150,
          text: "Nome Autoridade:",
          style: "subheader",
          bold: true,
          fontSize: 10,
        },
        { text: dataInfo.NOMEAUTORIDADE, fontSize: 10 },
      ],
    },
    {
      columns: [
        {
          width: 150,
          text: "CPF Autoridade:",
          style: "subheader",
          bold: true,
          fontSize: 10,
        },
        { text: dataInfo.CPFAUTORIDADE, fontSize: 10 },
      ],
    },
    {
      columns: [
        {
          width: 150,
          text: "Cargo Autoridade:",
          style: "subheader",
          bold: true,
          fontSize: 10,
        },
        { text: dataInfo.CARGOAUTORIDADE, fontSize: 10 },
      ],
    },
    {
      columns: [
        {
          width: 150,
          text: "Nome Homologador:",
          style: "subheader",
          bold: true,
          fontSize: 10,
        },
        { text: dataInfo.NOMEHOMOLOGADOR, fontSize: 10 },
      ],
    },
    {
      columns: [
        {
          width: 150,
          text: "Objeto:",
          style: "subheader",
          bold: true,
          fontSize: 10,
        },
        { text: dataInfo.OBJETO, fontSize: 10 },
      ],
    },
    {
      columns: [
        {
          width: 150,
          text: "Data Publicação:",
          style: "subheader",
          bold: true,
          fontSize: 10,
        },
        { text: dateRender(dataInfo.DATAPUBLICACAO), fontSize: 10 },
      ],
    },
    {
      columns: [
        {
          width: 150,
          text: "Data Abertura:",
          style: "subheader",
          bold: true,
          fontSize: 10,
        },
        { text: dateRender(dataInfo.DATAABERTURA), fontSize: 10 },
      ],
    },
    {
      columns: [
        {
          width: 150,
          text: "Tipo:",
          style: "subheader",
          bold: true,
          fontSize: 10,
        },
        { text: dataInfo.TIPO_LICITACAO_NOME, fontSize: 10 },
      ],
    },
    {
      columns: [
        {
          width: 150,
          text: "Procedimento:",
          style: "subheader",
          bold: true,
          fontSize: 10,
        },
        { text: dataInfo.PROCESSO_LICITACAO_NOME, fontSize: 10 },
      ],
    },
    {
      columns: [
        {
          width: 150,
          text: "Status:",
          style: "subheader",
          bold: true,
          fontSize: 10,
        },
        { text: dataInfo.STATUS_LICITACAO_NOME, fontSize: 10 },
      ],
    },
    {
      columns: [
        {
          width: 150,
          text: "Tipo Objeto:",
          style: "subheader",
          bold: true,
          fontSize: 10,
        },
        { text: dataInfo.TIPO_OBJETO_NOME, fontSize: 10 },
      ],
    },
    {
      columns: [
        {
          width: 150,
          text: "Valor Total da Despesa:",
          style: "subheader",
          bold: true,
          fontSize: 10,
        },
        { text: moneyFrom(dataInfo.VALORTOTALDESPESA), fontSize: 10 },
      ],
    },
    {
      columns: [
        {
          width: 150,
          text: "Valor da Dotação:",
          style: "subheader",
          bold: true,
          fontSize: 10,
        },
        { text: moneyFrom(dataInfo.VALORDOTACAO), fontSize: 10 },
      ],
    },
    {
      columns: [
        {
          width: 150,
          text: "Dotação Reforçada:",
          style: "subheader",
          bold: true,
          fontSize: 10,
        },
        { text: dataInfo.DOTACAOREFORCADA, fontSize: 10 },
      ],
    },
    {
      columns: [
        {
          width: 150,
          text: "Veículo de Divulgação:",
          style: "subheader",
          bold: true,
          fontSize: 10,
        },
        { text: dataInfo.VEICULODIVULGACAO, fontSize: 10 },
      ],
    },
    {
      columns: [
        {
          width: 150,
          text: "Data Homologação:",
          style: "subheader",
          bold: true,
          fontSize: 10,
        },
        { text: dateRender(dataInfo.DATAHOMOLOGACAO), fontSize: 10 },
      ],
    },
    {
      columns: [
        {
          width: 150,
          text: "Data Publicação Resultado:",
          style: "subheader",
          bold: true,
          fontSize: 10,
        },
        { text: dateRender(dataInfo.DATAPUBLICACAORESULTADO), fontSize: 10 },
      ],
    },
    {
      columns: [
        {
          width: 150,
          text: "Valor Adjudicado:",
          style: "subheader",
          bold: true,
          fontSize: 10,
        },
        { text: moneyFrom(dataInfo.VALORGLOBALADJUDICADO), fontSize: 10 },
      ],
    },
    {
      columns: [
        {
          width: 150,
          text: "Natureza da Despesa:",
          style: "subheader",
          bold: true,
          fontSize: 10,
        },
        { text: dataInfo.NATUREZADESPESA, fontSize: 10 },
      ],
    },
    {
      columns: [
        {
          width: 150,
          text: "Origem do Recurso:",
          style: "subheader",
          bold: true,
          fontSize: 10,
        },
        { text: dataInfo.ORIGEMRECURSO, fontSize: 10 },
      ],
    },
    {
      columns: [
        {
          width: 150,
          text: "Corona Vírus:",
          style: "subheader",
          bold: true,
          fontSize: 10,
        },
        { text: `${dataInfo.CORONAVIRUS == 1 ? "Não" : "Sim"}`, fontSize: 10 },
      ],
    },
  ]);

  data.map((d, i) => {
    arrayData2.push([
      { text: d.SACOP_DOCUMENTO_DATAENVIO, fontSize: 10 },
      { text: d.TIPO_DOCUMENTO_NOME, fontSize: 10 },
      { text: d.SACOP_DOCUMENTO_ASSUNTO, fontSize: 10 },
    ]);
  });

  const reportTitle = [{}];
  const details = [
    { image: logoPortalBase64, width: 50, height: 50, alignment: "center" },
    {
      text: `${NomeTipoPortal} de ${NomePortal}\n\n`,
      fontSize: 14,
      alignment: "center",
    },
    arrayInfor,
    {
      text: `Documentos da licitação\n\n`,
      fontSize: 14,
      alignment: "center",
      pageBreak: "before",
    },

    {
      style: "tableExample",
      table: {
        headerRows: 1,
        // dontBreakRows: true,
        // keepWithHeaderRows: 1,
        body: arrayData2,
      },
    },
  ];
  // const footer = [{text: `Arquivo gerado no portal: ${new Date().toLocaleString()} \n`,fontSize: 8, alignment: 'center'}]
  const footer = [{ text: `` }];

  const docDefinitions = {
    pageSize: "A4",
    pageMargins: [15, 50, 15, 40],
    headers: [reportTitle],
    content: [details],
    footer: [footer],
  };
  pdfMake.createPdf(docDefinitions).open(); //.print()//.download()
}
