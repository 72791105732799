import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import {
  NomeTipoPortal,
  NomePortal,
  linkArquivos,
} from "../../../../../../../services/variables";
import { logoPortalBase64 } from "../../../../../../../services/logoBase64";

export default function PDFgenerate(files, infor) {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const data = files;
  const dataInfo = infor;
  //console.log(files,infor)

  const dateRender = (dateReq) => {
    const nameMonth = {
      0: "01",
      1: "02",
      2: "03",
      3: "04",
      4: "05",
      5: "06",
      6: "07",
      7: "08",
      8: "09",
      9: "10",
      10: "11",
      11: "12",
    };
    const date = new Date(dateReq);
    if (dateReq == "") {
      return "";
    } else {
      date.setDate(date.getDate() + 1);

      var day = date.getDate();
      if (date.getDate() < 10) {
        var day = "0" + date.getDate();
      }
      const formatDate =
        day + "/" + nameMonth[date.getMonth()] + "/" + date.getFullYear();

      return formatDate;
    }
  };

  const moneyFrom = (money) => {
    if (money == "" || typeof money == String) {
    } else {
      var format = { minimumFractionDigits: 2, currency: "BRL" };
      return money.toLocaleString("pt-BR", format);
    }
  };

  const arrayInfor = [];
  const arrayData2 = [
    [
      { text: "DATA MOVIMENTAÇÃO", bold: true, fontSize: 11 },
      { text: "NOME", bold: true, fontSize: 11 },
      { text: "ASSUNTO", bold: true, fontSize: 11 },
    ],
  ];

  arrayInfor.push([
    {
      //fontSize: 10
      columns: [
        {
          width: 150,
          text: "Número Contrato:",
          style: "subheader",
          bold: true,
          fontSize: 10,
        },
        { text: dataInfo.NUMERO_CONTRATO, fontSize: 10 },
      ],
    },
    {
      columns: [
        {
          width: 150,
          text: "Exercício:",
          style: "subheader",
          bold: true,
          fontSize: 10,
        },
        { text: dataInfo.EXERCICIO_CONTRATO, fontSize: 10 },
      ],
    },
    {
      columns: [
        {
          width: 150,
          text: "Unidade:",
          style: "subheader",
          bold: true,
          fontSize: 10,
        },
        { text: dataInfo.UNIDADE_NOME, fontSize: 10 },
      ],
    },
    {
      columns: [
        {
          width: 150,
          text: "Fornecedor:",
          style: "subheader",
          bold: true,
          fontSize: 10,
        },
        { text: dataInfo.NOME_FORNECEDOR, fontSize: 10 },
      ],
    },
    {
      columns: [
        {
          width: 150,
          text: "Fornecedor CNPJ:",
          style: "subheader",
          bold: true,
          fontSize: 10,
        },
        { text: dataInfo.FORNECEDOR_CPF_CNPJ, fontSize: 10 },
      ],
    },
    {
      columns: [
        {
          width: 150,
          text: "Valor Contrato:",
          style: "subheader",
          bold: true,
          fontSize: 10,
        },
        { text: moneyFrom(dataInfo.VALOR_CONTRATO), fontSize: 10 },
      ],
    },
    {
      columns: [
        {
          width: 150,
          text: "Objeto:",
          style: "subheader",
          bold: true,
          fontSize: 10,
        },
        { text: dataInfo.OBJETO, fontSize: 10 },
      ],
    },
    {
      columns: [
        {
          width: 150,
          text: "Data Início Contrato:",
          style: "subheader",
          bold: true,
          fontSize: 10,
        },
        { text: dateRender(dataInfo.DATA_INICIO_CONTRATO), fontSize: 10 },
      ],
    },
    {
      columns: [
        {
          width: 150,
          text: "Data Fim Contrato:",
          style: "subheader",
          bold: true,
          fontSize: 10,
        },
        { text: dateRender(dataInfo.DATA_FIM_CONTRATO), fontSize: 10 },
      ],
    },

    {
      columns: [
        {
          width: 150,
          text: "Data Publicação:",
          style: "subheader",
          bold: true,
          fontSize: 10,
        },
        { text: dateRender(dataInfo.DATA_ENVIO_PUBLICACAO), fontSize: 10 },
      ],
    },
    {
      columns: [
        {
          width: 150,
          text: "Data Assinatura:",
          style: "subheader",
          bold: true,
          fontSize: 10,
        },
        { text: dateRender(dataInfo.DATA_ASSINATURA), fontSize: 10 },
      ],
    },
  ]);

  data.map((d, i) => {
    arrayData2.push([
      { text: d.SACOP_DOCUMENTO_DATAENVIO, fontSize: 10 },
      { text: d.TIPO_DOCUMENTO_NOME, fontSize: 10 },
      { text: d.SACOP_DOCUMENTO_ASSUNTO, fontSize: 10 },
    ]);
  });

  const reportTitle = [{}];
  const details = [
    { image: logoPortalBase64, width: 50, height: 50, alignment: "center" },
    {
      text: `${NomeTipoPortal} de ${NomePortal}\n\n`,
      fontSize: 14,
      alignment: "center",
    },
    arrayInfor,
    {
      text: `Documentos\n\n`,
      fontSize: 14,
      alignment: "center",
      pageBreak: "before",
    },

    {
      style: "tableExample",
      table: {
        headerRows: 1,
        // dontBreakRows: true,
        // keepWithHeaderRows: 1,
        body: arrayData2,
      },
    },
  ];
  // const footer = [{text: `Arquivo gerado no portal: ${new Date().toLocaleString()} \n`,fontSize: 8, alignment: 'center'}]
  const footer = [{ text: `` }];

  const docDefinitions = {
    pageSize: "A4",
    pageMargins: [15, 50, 15, 40],
    headers: [reportTitle],
    content: [details],
    footer: [footer],
  };
  pdfMake.createPdf(docDefinitions).open(); //.print()//.download()
}
